<template>
  <CInput
    :label="label"
    :disabled="disabled"
    :required="required"
    :type="hide ? 'password' : null"
    :invalid-feedback="invalidFeedback"
    :is-valid="isValid"
    :value="value"
    @input="$emit('input', $event)"
    v-on="$listeners"
  >
    <template #append>
      <CButton color="secondary" size="sm" v-on:click="hide = !hide">
        <CIcon :name="hide ? 'cilLockLocked' : 'cilLockUnlocked'" />
      </CButton>
    </template>
  </CInput>
</template>

<script>
export default {
  name: 'HiddenInput',
  props: {
    value: String,
    invalidFeedback: String,
    isValid: Boolean,
    required: Boolean,
    label: String,
    disabled: Boolean,
  },
  data() {
    return {
      hide: true
    }
  }
}
</script>
