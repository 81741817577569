<template>
  <CCard body-wrapper>
    <h5>Настройка 1С</h5>
    <CElementCover
      v-if="loading"
      :opacity="0.8"
    >
      <CSpinner
        class="custom-spinner"
        color="info"
      />
    </CElementCover>
    <CForm
      v-if="form.data"
      class="mt-5"
      @submit.prevent="submit"
    >
      <CCard>
        <CCardBody>
          <CInput
            label="Логин"
            disabled
            :invalid-feedback="getScalarFieldError(form.errors, 'login')"
            :is-valid="isScalarFieldInvalid(form.errors, 'login') ? false : null"
            :value.sync="form.data.login"
          />
          <HiddenInput
            label="Пароль"
            required
            :invalid-feedback="getScalarFieldError(form.errors, 'password')"
            :is-valid="isScalarFieldInvalid(form.errors, 'password') ? false : null"
            :value.sync="form.data.password"
          />
          <CInput
            label="Адрес сервера"
            description="Рекомендуем не менять этот параметр"
            :invalid-feedback="getScalarFieldError(form.errors, 'serverAddress')"
            :is-valid="isScalarFieldInvalid(form.errors, 'serverAddress') ? false : null"
            :value.sync="form.data.serverAddress"
          />
          <CRow>
            <CCol col="auto">
              <loading-button :loading="form.updating" />
            </CCol>
            <CCol
              class="p-0"
              col="auto"
            >
              <div class="text-right">
                <CButton
                  color="dark"
                  @click="$router.go(-1)"
                >
                  Закрыть
                </CButton>
              </div>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CForm>
  </CCard>
</template>

<script>
import LoadingButton from '@/components/loading-button';
import FormMixins from '@/mixins/form-mixins';
import Swal from 'sweetalert2';
import HiddenInput from '@/components/hidden-input';

export default {
  name: 'ShowOneSSetting',
  mixins: [FormMixins],
  components: {
    LoadingButton,
    HiddenInput
  },
  data() {
    return {
      form: {
        data: null,
        updating: false,
        errors: {}
      },
      loading: false
    }
  },
  mounted() {
    this.load();
  },
  computed: {
    resourceId() {
      return this.$route.params.id;
    }
  },
  methods: {
    async load() {
      try {
        this.loading = true;
        const {data} = await this.$axios.get(`/api/one_s/settings/${this.resourceId}`);
        this.form.data = data;
      } finally {
        this.loading = false;
      }
    },
    async submit() {
      try {
        this.form.updating = true;
        this.form.errors = {};
        const {data} = await this.$axios.put(`/api/one_s/settings/${this.resourceId}`, this.form.data);
        this.form.data = data;
        await Swal.fire(
          'Успешно',
          'Настройка 1С обновлена',
          'success',
        );
      } catch(e) {
        if (this.hasErrorsInResponse(e)) {
          this.form.errors = e.response.data.errors
        }
      } finally {
        this.form.updating = false;
      }
    }
  }
}
</script>
